import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Outlet, useNavigate } from 'react-router-dom';

import { DataTree } from 'containers/data-tree';
import { ItemDetail } from 'containers/item-detail';
import ErrorBoundary from '../../components/error-boundary';
import { AppTopBar } from '../app-top-bar';
import { AppContainer } from '../app-container/app-container';

const useStyles = makeStyles(() => ({
    layoutWrapper: {
        height: '100%',
        // width: '100%',
        backgroundColor: 'var(--main-surface-background)',
    },
    contentWrapper: {
        height: `calc(100% - 64px)`,
        overflowY: 'hidden',

        '& > div': {
            width: 'unset',
            padding: '0',
        },
    },
}));

export default () => {
    const classes = useStyles();
    const [page, setPage] = useState<string>('');

    useEffect(() => {
        setPage(window.location.href);
    }, [window.location.href]);

    // return (
    //     <div className={`grid ${classes.layoutWrapper}`}>
    //         <ErrorBoundary navigate={useNavigate()}>
    //             <AppTopBar />
    //             <div className="col-2 flex flex-column p-0 main-sidebar">
    //                 <DataTree />
    //                 <ItemDetail />
    //             </div>
    //             <div className={`col-10 ${classes.contentWrapper} p-0`}>
    //                 <AppContainer>
    //                     <Outlet />
    //                 </AppContainer>
    //             </div>
    //         </ErrorBoundary>
    //     </div>
    // );
    return (
        <div className={`grid ${classes.layoutWrapper}`}>
            {page.indexOf('editor') === -1 && (
                <ErrorBoundary navigate={useNavigate()}>
                    <AppTopBar />
                    <div className="col-2 flex flex-column p-0 main-sidebar">
                        <DataTree />
                        <ItemDetail />
                    </div>
                    <div className={`col-10 ${classes.contentWrapper} p-0`}>
                        <AppContainer>
                            <Outlet />
                        </AppContainer>
                    </div>
                </ErrorBoundary>
            )}
            {page.indexOf('editor') !== -1 && (
                <ErrorBoundary navigate={useNavigate()}>
                    <AppTopBar />
                    <div className={`col-12 ${classes.contentWrapper} p-0`}>
                        <AppContainer>
                            <Outlet />
                        </AppContainer>
                    </div>
                </ErrorBoundary>
            )}
        </div>
    );
};
