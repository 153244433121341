import React, { ReactElement } from 'react';
import { Box, Card, makeStyles, Theme, Typography } from '@material-ui/core';
import { ObjectResponseType } from 'models/objects/object/types';
import { ProgressBar } from 'primereact/progressbar';
import { Divider } from 'primereact/divider';
import { formatDate } from '../../../../../utils/date';

const useStyles = makeStyles(({ spacing }: Theme) => ({
    objectCard: {
        padding: '4px 20px 20px 20px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 64,
        marginBottom: spacing(7),
    },
}));

interface IObjectCardPropsProps {
    selectedObject: ObjectResponseType | null;
}

export const ObjectCard = ({ selectedObject }: IObjectCardPropsProps) => {
    const classes = useStyles();

    return (
        <div className={classes.objectCard}>
            <Divider className="divider-tree" />
            <div className="object-card-content">
                <p className="text-base font-bold mb-3">
                    Объект {selectedObject?.name || ''}
                </p>

                <div className="mb-3">
                    <p className="text-sm font-normal text-content-secondary">
                        Проектное давление, МПа
                    </p>
                    <p className="text-base font-normal text-content-primary">
                        {selectedObject?.project_pressure
                            ? (selectedObject?.project_pressure).toFixed(2)
                            : ''}
                    </p>
                </div>

                <div className="mb-3">
                    <p className="text-sm font-normal text-content-secondary">
                        Проектная протяженность, км
                    </p>
                    <p className="text-base font-normal text-content-primary">
                        {selectedObject?.project_length
                            ? (
                                  Number(selectedObject.project_length) / 1000
                              ).toFixed(3)
                            : ''}
                    </p>
                </div>

                <div className="mb-3">
                    <p className="text-sm font-normal text-content-secondary">
                        Текущая протяженность, км
                    </p>
                    <p className="text-base font-normal text-content-primary mb-1">
                        {selectedObject?.real_length
                            ? (selectedObject.real_length / 1000).toFixed(3)
                            : ''}
                    </p>
                    <ProgressBar
                        value={
                            selectedObject?.real_length &&
                            selectedObject?.project_length
                                ? (
                                      (+selectedObject.real_length /
                                          +selectedObject.project_length) *
                                      100
                                  ).toFixed(2)
                                : 0
                        }
                    />
                </div>

                <div className="mb-3">
                    <p className="text-sm font-normal text-content-secondary">
                        Проектное количество стыков, шт
                    </p>
                    <p className="text-base font-normal text-content-primary">
                        {selectedObject?.project_join_count || ''}
                    </p>
                </div>

                <div>
                    <p className="text-sm font-normal text-content-secondary">
                        Текущее количество стыков, шт
                    </p>
                    <p className="text-base font-normal text-content-primary mb-1">
                        {selectedObject?.join_count || ''}
                    </p>
                    <ProgressBar
                        value={
                            selectedObject?.join_count &&
                            selectedObject?.project_join_count
                                ? (selectedObject?.join_count /
                                      +selectedObject?.project_join_count) *
                                  100
                                : 0
                        }
                    />
                </div>
            </div>
        </div>
    );
};
