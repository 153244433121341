import React, { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectObjectState } from 'models/objects/object/selectors';
import { ObjectCard } from 'pages/gas-pipeline/pages/sectors/forms/object-card';
import { selectSectorState } from 'models/objects/sector/selectors';

import { selectWorkgroupState } from 'models/objects/workgroup/selectors';
import { SectorCard } from 'pages/gas-pipeline/pages/weldbook/forms/sector-card';
import { selectWeldBookState } from 'models/weldbooks/weldbook/selectors';

export const ItemDetail: React.FC = () => {
    const { objectById, isLoading: isObjectLoading } =
        useAppSelector(selectObjectState);
    const { sectorById, isLoading: isSectorLoading } =
        useAppSelector(selectSectorState);
    const { workgroupById, isLoading: executorIsLoading } =
        useAppSelector(selectWorkgroupState);
    const { weldBookDetail } = useAppSelector(selectWeldBookState);
    useEffect(() => {}, []);

    return (
        <>
            <div
                className={`${
                    window.location.pathname.includes('sectors') ||
                    window.location.pathname.includes('weldbook')
                        ? 'card ml-2 card-tree-object-pick'
                        : 'card ml-2 card-tree-object-pick-full'
                }`}
            >
                {objectById && window.location.pathname.includes('sectors') && (
                    <ObjectCard selectedObject={objectById} />
                )}
                {sectorById &&
                    window.location.pathname.includes('weldbook') && (
                        <SectorCard selectedSector={sectorById} />
                    )}
            </div>
        </>
    );
};
