import React from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem } from 'primereact/menuitem';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
    breadcrumb: {
        fontSize: '0.875rem',
        height: '21px',
        width: '100%',
        border: 'none',
        '& ul > li:first-of-type': {
            display: 'none',
        },
        '& ul > li': {
            margin: '0px 5px 5px',
            color: 'var(--content-secondary)',
            width: 'unset',
            display: 'contents',
        },
        '& ul > li:last-of-type': {
            opacity: '.6',
        },
        '& ul': {
            height: '10px',
        },
        '& ul > li > a': {
            textDecoration: 'none',
            color: 'var(--content-secondary)',
            margin: '0px 5px 5px',
        },
        '& ul > li > svg': {
            margin: '0px 5px 5px',
        },
    },

    link: {
        textDecoration: 'none',
        color: 'inherit',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

interface IBreadcrumbsData {
    items: MenuItem[];
}
export const WbBreadcrumbs = ({ items }: IBreadcrumbsData) => {
    const classes = useStyles();
    return <BreadCrumb model={items} className={classes.breadcrumb} />;
};
